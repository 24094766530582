import $ from "jquery";

function animateLabels() {
    $(document).each(function(){
        let me = $(this);
        if (me.val() != '') {
            $("label[for='" + me.attr('id') + "']").addClass("animate-label");
        }
    })
}

function slideLabelsUp() {
    $(document).on('focus', 'form input', function(){
        let me = $(this);
        $("label[for='" + me.attr('id') + "']").addClass("animate-label");
    });
    $(document).on('blur', 'form input', function(){
        if ($('form input').val() == "") {
            let me = $(this);
            $("label[for='" + me.attr('id') + "']").removeClass("animate-label");
        }
    });
}

function readMoreBio(){
    $(document).on('click touchend', '.bio button', function(){
        var siblings = $(this).siblings();
        siblings.toggleClass('open');
    
        if(siblings.hasClass('open')){
            $(this).html('Loka / Close');
        } else {
            $(this).html('Lesa meira / Read more');
        }
    });
}

$(function() {
    animateLabels();
    slideLabelsUp();
    readMoreBio();
})