import 'bootstrap/dist/css/bootstrap.css';
import gis from './images/gis-blom.png';
import './styles/main.scss';
import './js/main.js';
import Feed from './feed.js';
import Bio from './bio.js';


function App() {
  return (
    <div className="App container-fluid p-0">
      <header>
        <div className="container-fluid nav-container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between navigation">
              <span className="page-title">In Memoriam</span>
              <a className="d-flex align-items-center justify-content-center button" href="#write-post">Skrifa kveðju / Write a memory</a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <img src={gis} />
            </div>
            <div className='col-md-6 col-12 text-col d-flex flex-column justify-content-center'>
              <h1>Gísli Þór Guðmundsson</h1>
              <h1 className='blue'>Gis Von Ice</h1>
              <span>11. júní 1961 - 13. júlí 2023</span>
              <span>Hann mun lifa að eilífu í hugum okkar og hjörtum</span>
            </div>
          </div>
        </div>
      </header>
      <div className='container-fluid section p-0'>
        <Bio />
        <Feed />
      </div>
    </div>
  );
}

export default App;
