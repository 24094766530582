import React, { useEffect, useState } from 'react';


// Biography
const Bio = () => {

    return (
        <div className="feed bio container">
            <div className='row'>
                <div className="white-container form-container col-12">
                    <h1 className='section-title'>Æviágrip / Biography</h1>
                    <p>
                        Gísli Þór Guðmundsson fæddist í Ásbyrgi á Ísafirði 11. júní 1961
                        <br></br>
                        <br></br>
                        Eftirlifandi eiginkona hans er Anna Hildur Hildibrandsdóttir, kvikmyndagerðarkona og fagstjóri skapandi greina við Háskólann á Bifröst.
                        Dætur þeirra eru Álfrún Gísladóttir leikkona og Embla Gísladóttir viðburðastjóri. Börn Emblu eru Harry Adamsson Derwin og Alexia Valerie Adamsdóttir Derwin.
                        <br></br>
                        <br></br>
                        Foreldrar Gísla voru Ragna Sólberg talsímavörður frá Suðureyri d. 01.10. 2010 og Guðmundur Hilaríus Gíslason sjómaður frá Ísafirði d. 29.11.1974. Gísli átti þrjú systkini. Sóley Guðmundsdóttir gift Ingvari Reynissyni, börn Sóleyjar eru: Ragna Björk, Andrea Eik og Guðmundur Reynir.
                        <br></br>
                        <br></br>
                        Sammæðra Rafn Ragnar Jónsson d. 27.06.2004. Rafn var giftur Friðgerði Guðmundsdóttir. Börn Rafns eru: Helga Rakel, Egill Örn, Ragnar Sólberg og Rafn Ingi.
                        <br></br>
                        <br></br>
                        Samfeðra Guðbjörg Guðmundsdóttir. Börn Guðbjargar eru: Gunnar Már, Ástþór Ægir, Guðmundur Gísli, Garðar Smári, Jóhann Helgi og Þuríður.
                        <br></br>
                        <br></br>
                        Gísli, Sóley og Rabbi ólust upp saman á Ísafirði en Guðbjörg ólst upp hjá móður sinni á Akureyri. Gísli ólst upp í faðmi ástríkrar og skemmtilegrar fjölskyldu.
                        Hann var vinamargur og vinsæll og hélt ævilöngum vinskap við marga af æskufélögum sínum. Gísli var efnilegur skákmaður og fremstur í þeirri íþrótt á meðal jafningja um skeið. Árið 1974 missti Gísli föður sinn í sjóslysi og þar með fyrirmynd sem hann lærði mikið af og leit upp til. Honum var tíðrætt um réttsýni og manngæsku föður síns. Um leið dáði hann móður sína og átti við hana mikið og gott vináttusamband. Gísli var snemma diskótekari í Sjallanum á Ísafirði með Villa vini sínum og vann síðar í plötubúð og sem rótari í hljómsveitum hjá Rabba bróður sínum. Eftir bíóferð í Alþýðuhúsið árið 1980 ákvað Gísli að flytja til Gautaborgar í Svíþjóð með Hirti Grétarssyni frænda sínum og vini og bjuggu þeir þar með Gunnari Svavarssyni vini sínum í tvö ár.
                        <br></br>
                        <br></br>
                        Gísli Þór útskrifaðist sem stúdent frá Flensborgarskóla árið 1985 og síðar sem prentsmiður frá Iðnskólanum í Reykjavík. Hann fluttist ásamt fjölskyldu sinni til London árið 1991 og útskrifaðist sem grafískur hönnuður frá Ravenswood College árið 1995. Hann starfaði sem slíkur hjá Elsevier útgáfunni í 12 ár. Gísli gerðist síðar umboðsmaður og vann með listamönnum á borð við Sign, Botnleðju, For A Minor Reflection, Boys in a Band, LayLow, Vök, Hatara og Systrum. Hann gekk undir nafninu Gis von Ice í tónlistargeiranum og var örlátur á ráðleggingar og tengingar við fjölda tónlistarmanna. Hann brann fyrir að miðla og hjálpa þeim sem voru að stíga sín fyrstu skref. Gísli Þór sinnti einnig stafrænni dreifingu á tónlist og vann sem ráðgjafi fyrir tónlistarhátíðir á borð við G! í Færeyjum og Tallinn Music Week í Eistlandi. Hann flutti til Íslands árið 2021 og rak þar menningarframleiðslufyrirtækið Glimrandi með eiginkonu sinni. Þar sinnti hann margvíslegum verkefnum bæði á sviði kvikmyndagerðar og tónlistar síðustu árin sín.
                        <br></br>
                        <br></br>
                        Gísli Þór og Anna Hildur fundu hjörtu hvors annars í Gautaborg árið 1981. Þar hófst ævintýralegt ferðlag þeirra í gegnum lífið sem einkenndist af samvinnu og fjöbreyttum verkefnum í menningargeiranum. Af öllu sem Gísli og Anna áorkuðu saman var hann stoltastur af dætrum sínum tveimur Álfrúnu og Emblu. Árið 2008 eignaðist Gísli Amsterdam á Ísafirði, hús ömmu sinnar Þorbjargar Líkafrónsdóttur, með Sóley systur sinni. Þar undi Gísli sér vel á sumrin enda átti hann ófár minningar frá því að hafa dvalið þar löngum stundum sem barn í góðu yfirlæti ömmu sinnar.
                        <br></br>
                        <br></br>
                        Gísli Þór var fjölskyldumaður fram í fingurgóma sem tengdist, eiginkonu sinni dætrum, barnabörnum og öllum sínum nánustu sterkum kærleiks- og vináttuböndum. Alltaf til staðar og alltaf hvetjandi í öllu sem hans fólk tók sér fyrir hendur. Hann var húmoristi, húmanisti, femínisti og friðarsinni sem átti sér draum um betri heim þar sem allir fengju að blómstra á sínum forsendum.
                        <br></br>
                        <br></br>
                        <br></br>
                        <h6 className="bold">English version</h6>
                        Gísli Þór Guðmundsson was born in Ásbyrgi in Ísafjörður on June 11th 1961.
                        <br></br>
                        <br></br>
                        He is survived by his wife Anna Hildur Hildibrandsdóttir, film maker and Programme Leader for creative industries at Bifröst University. Their daughters are Álfrún Gísladóttir, actress, and Embla Gísladóttir, event manager. Embla‘s children are Harry Adamsson Derwin and Alexia Valerie Adamsdóttir Derwin.
                        <br></br>
                        <br></br>
                        Gísli‘s parents were Ragna Sólberg, telephone operator from Suðureyri, d. October 10th 2010, and Guðmundur Hilaríus Gíslason, fisherman from Ísafjörður, d. November 29th 1974. Gísli had three siblings. Sóley Guðmundsdóttir married to Ingvar Reynisson; her children are Ragna Björk, Andrea Eik and Guðmundur Reynir. His half-brother by the same mother was Rafn Ragnar Jónsson (Rabbi), d. June 27th 2004. Rafn was married to Friðgerður Guðmundsdóttir. His children are Helga Rakel, Egill Örn, Ragnar Sólberg and Rafn Ingi. His half-sister by the same father is Guðbjörg Guðmundsdóttir. Her children are Gunnar Már, Ástþór Ægir, Guðmundur Gísli, Garðar Smári, Jóhann Helgi and Þuríður.
                        <br></br>
                        <br></br>
                        Gísli, Sóley and Rabbi grew up together in Ísafjörður while Guðbjörg grew up with her mother in Akureyri. Gísli was raised in a caring and fun-loving family. He was popular and had numerous friends, maintaining life-long friendships with many of his playmates. Gísli was a promising chess-player and was the first among equals in that game for a while. In 1974 his father had a fatal accident at sea, robbing Gísli of a father figure he had admired and learned much from. Gísli frequently spoke of his father‘s moral integrity and compassion. He also revered his mother, and their friendship was strong and close.  At an early age Gísli was a DJ in Sjallinn in Ísafjörður with his friend Villi, and he later worked in a record shop and as a roadie for his brother Rabbi‘s music bands.  After a visit to the cinema in Alþýðuhúsið in 1980 Gísli decided to move to Gothenburg in Sweden with his cousin and friend Hjörtur Grétarsson, where they lived for two years with their friend Gunnar Svavarsson.
                        <br></br>
                        <br></br>
                        Gísli Þór graduated from Flensborg college in 1985 and later as a typesetter from Iðnskólinn in Reykjavík. He moved with his family to London in 1991 and completed a degree in graphic design from Ravenswood College in 1995. He worked as a graphic designer for Elsevier publishers for 12 years.  He then became an agent and worked with artists like Sign, Botnleðja, For A Minor Reflection, Boys in a Band, LayLow, Vök, Hatari and Systur. Known in the music business as Gis von Ice, Gísli was always generous with his advice and wide network of contacts.  He had a passion for promoting and helping those who were taking the first steps of their careers. Gísli was also involved in digital distribution of music and was an advisor for music festivals like G! in the Faroes and the Tallinn Music Week in Estonia. He moved back to Iceland in 2021 where he ran the artistic production company Glimrandi together with his wife, managing various film and music related projects in the last years of his life. 
                        <br></br>
                        <br></br>
                        Gísli Þór and Anna Hildur found each other in Gothenburg in 1981.  This was the start of their amazing journey through life, a journey characterised by co-operation and a variety of cultural projects. Of all their joint achievements, Gísli was most proud of his two daughters, Álfrún and Embla. Since 2008 he has owned, together with his sister Sóley, the house Amsterdam in Ísafjörður. It had been the house of his grandmother, Þorbjörg Líkafrónsdóttir, and there he enjoyed many summers surrounded by happy memories of his frequent childhood stays with his loving nana.
                        <br></br>
                        <br></br>
                        Gísli Þór was a dedicated family man who had the strongest ties of friendship and love with his wife, daughters, grandchildren, and wider family.  Always there for others, always a source of encouragement in everything his people were doing. He was a humourist, humanist, feminist, and pacifist who dreamt of a better world where everyone could thrive on their own merits.  
                        <br></br>
                    </p>
                    <button>Lesa meira / Read more <span className="d-block">English version below</span></button>
                </div>
            </div>
        </div>
    );
};

export default Bio;