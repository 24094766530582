// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCLRkK7BxY1DMYUqufsbt3iYBq-o8IttY0",
  authDomain: "gis-von-ice.firebaseapp.com",
  projectId: "gis-von-ice",
  storageBucket: "gis-von-ice.appspot.com",
  messagingSenderId: "141131858780",
  appId: "1:141131858780:web:17e5f9d378900ecaba7799"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const storage = getStorage(app);
export const firestoreDb = getFirestore(app);
